<!-- 谐波监测 -->
<template>
  <div id="wave-monitor">
    <!-- <div class="tree">
      <div class="tree-title">变配电站</div>
      <el-tree ref="tree" :default-expand-all="false" :show-checkbox="false" :data="treeData"
        :check-on-click-node="false" :props="defaultProps" node-key="id" @node-click="handleCheck"></el-tree>
    </div> -->
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>谐波监测</span>
      </div>
      <div class="content">
        <div class="titleEcharts">
          <div class="titleItem">
            <div class="itemTitle">
              <img src="../../assets/images/LTicon.png" alt="">
              <div class="">三相电压不平衡度</div>
            </div>
            <div class="itemOne">
              <!-- <div id="oneEcharts" class="oneEcharts"></div> -->
              <div style="margin-bottom:-30px;"><span style="font-size: 22px;">ε</span><span style="font-size: 14px;">u</span>: {{twice_navg_u}}</div>
              <img v-if="twice_navg_u_pass == 0" src="../../assets/images/wave/blue2.png" alt="">
              <img v-if="twice_navg_u_pass == 1" src="../../assets/images/wave/yellow2.png" alt="">
            </div>
          </div>
          <div class="titleItem">
            <div class="itemTitle">
              <img src="../../assets/images/LTicon.png" alt="">
              <div class="">三相电流不平衡度</div>
            </div>
            <div class="itemOne">
              <!-- <div id="twoEcharts" class="oneEcharts"></div> -->
              <div style="margin-bottom:-30px;"><span style="font-size: 22px;">ε</span><span style="font-size: 14px;">I</span>: {{twice_navg_i}}</div>
              <img v-if="twice_navg_i_pass == 0" src="../../assets/images/wave/blue2.png" alt="">
              <img v-if="twice_navg_i_pass == 1" src="../../assets/images/wave/yellow2.png" alt="">
            </div>
          </div>
          <div class="titleItem">
            <div class="itemTitle">
              <img src="../../assets/images/LTicon.png" alt="">
              <div class="">功率因数</div>
            </div>
            <div class="itemOne itemThree">
              <div id="threeEcharts" class="threeEcharts"></div>
              <!-- <img src="../../assets/images/wave/yellow.png" alt=""> -->
            </div>
          </div>
        </div>
      </div>
      <div class="bodyContent">
        <div class="search">
          <el-form class="form" ref="form" :inline="true" :model="formInline">
            <el-form-item label="选择类别：">
              <el-select :popper-append-to-body="false" v-model="formInline.title">
                <el-option v-for="(item,index) in selectList" :key="index" 
                :label="item.name" :value="item.value"></el-option>
                
                <!-- <el-option label="场站2" value="2"></el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit" icon="el-icon-search" class="custom-button">查询</el-button>
            </el-form-item>
          </el-form>
          <div class="">
            <!-- <el-button size="small" type="warning" class="">图表</el-button>
            <el-button size="small" type="primary" class="">数据</el-button> -->
          </div>
        </div>
        <div class="contentTitle">
          {{ chartsTitle }}
        </div>
        <div id="contentEcharts" class="contentEcharts"></div>

      </div>
    </div>

  </div>
</template>
<script>
import  {emissionsCarbonApi}  from '@/api/emissionsCarbon.js'
import * as echarts from "echarts";
export default {
  name: "",

  data() {
    return {
      formInline: {
        title: 'twice_thd_ua'
      },
      once_cosq_e:'',
      twice_navg_u:'',//
      twice_navg_i:'',
      twice_navg_i_pass:'',
      twice_navg_u_pass:'',
      operation: false,
      requestParam: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      checkedNodes: [],
      chartsTitle:'A相电压谐波',
      selectList:[
        {
          name:'A相电压谐波',
          value:'twice_thd_ua',
        },
        {
          name:'B相电压谐波',
          value:'twice_thd_ub',
        },
        {
          name:'C相电压谐波',
          value:'twice_thd_uc',
        },
        {
          name:'A相电流谐波',
          value:'twice_thd_ia',
        },
        {
          name:'B相电流谐波',
          value:'twice_thd_ib',
        },
        {
          name:'C相电流谐波',
          value:'twice_thd_ic',
        },
      ],
      stationNumber:'',
    };
  },
  created(){
    this.$store.commit('increment', '电能质量')
    this.$store.commit('selectChild', '谐波监测')
    this.$store.commit("selectChildren", "");
  },

  mounted() {

    // this.drawChart();
    // this.drawChartTwo();
    // this.drawChartThree();
    // this.drawEnergy();
    this.stationNumber = sessionStorage.getItem('stationNumber')
    this.getPie()
    this.drawEnergy()

  },

  methods: {
   
    //电流-电压不平衡-功率因数
    getPie(){
      let obj={
        stationNumber:this.stationNumber
      }
      emissionsCarbonApi.getStationOnceCosq(obj).then(res=>{
        //once_cosq_e 功率因数
        //twice_navg_u 电压不平衡度
        //twice_navg_i 电流不平衡度

        let once_cosq_e =res.data.once_cosq_e
        this.twice_navg_i_pass = res.data.twice_navg_i_pass//1是不合格，0是合格
        this.twice_navg_u_pass = res.data.twice_navg_u_pass
        this.twice_navg_u =res.data.twice_navg_u 
        this.twice_navg_i =res.data.twice_navg_i
        // this.drawChart(twice_navg_u);//三项电压不平衡
        // this.drawChartTwo(twice_navg_i);//三项电流不平衡
        this.drawChartThree(once_cosq_e);//功率因数

      })
    },
    
    //三相电压不平衡度
    drawChart(data) {
      var chart = echarts.init(document.getElementById("oneEcharts"));
      let dataNo= 1-data
      var option = {
        backgroundColor: '#061A42',
        tooltip: {
          trigger: 'item'
        },
        color: ['#1890FF', '#0D40A4',], // 使用默认颜色库
        series: [
          {
            type: 'pie',
            radius: '70%',
            label: {
              fontSize: 14,
            },
            data: [
              { value: data, name: '不平衡度(KVA)' },
              { value: dataNo, name: '', labelLine: { show: false } },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };

      chart.setOption(option);
    },
    //三相电流不平衡度
    drawChartTwo(data) {
      var chart = echarts.init(document.getElementById("twoEcharts"));
      let dataNO=100-data
      var option = {
        backgroundColor: '#061A42',
        tooltip: {
          trigger: 'item'
        },
        color: ['#E0AB34', '#4E493D',], // 使用默认颜色库
        series: [
          {
            type: 'pie',
            radius: '70%',
            label: {
              fontSize: 14,
            },
            data: [
              { value: data, name: '不平衡度(KVA)' },
              { value: dataNO, name: '', labelLine: { show: false } },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };

      chart.setOption(option);
    },
    //功率因数
    drawChartThree(data) {
      var chart = echarts.init(document.getElementById("threeEcharts"));
      var itemNum = data; // 要判断的数值
      var option = {
        tooltip: {
          formatter: '{a} <br/>{b} : {c}'
        },
        backgroundColor: '#061A40',
        series: [{
          type: 'gauge',
          radius: '100%',

          axisLine: {
            show: true,
            offsetCenter: ['-50%', '-50%'],
            lineStyle: {
              width: 8,
              color: intervals
              // color: [
              //   [0, 'rgba(0,0,0,0)'],  // 空隙的颜色
              //   [0.1, '#2FC9CB'],
              //   [0.11, 'rgba(0,0,0,0)'], // 空隙的颜色
              //   [0.2, '#2FC9CB'],
              //   [0.21, 'rgba(0,0,0,0)'], // 空隙的颜色
              //   [0.3, '#2FC9CB'],
              //   [0.31, 'rgba(0,0,0,0)'], // 空隙的颜色
              //   [0.4, '#2FC9CB'],
              //   [0.41, 'rgba(0,0,0,0)'], // 空隙的颜色
              //   [0.5, '#2FC9CB'],
              //   [0.51, 'rgba(0,0,0,0)'], // 空隙的颜色
              //   [0.6, '#2FC9CB'],
              //   [0.61, 'rgba(0,0,0,0)'], // 空隙的颜色
              //   [0.7, '#2FC9CB'],
              //   [0.71, 'rgba(0,0,0,0)'], // 空隙的颜色
              //   [0.8, '#2FC9CB'],
              //   [0.81, 'rgba(0,0,0,0)'], // 空隙的颜色
              //   [0.9, '#2FC9CB'],
              //   [0.91, 'rgba(0,0,0,0)'], // 空隙的颜色
              //   [1, '#2FC9CB']
              // ]
            }
          },
          axisTick: { show: false },
          splitLine: { show: false },
          pointer: {
            show: true,
            itemStyle: {
              color: '#2FC9CB' // 设置指针的颜色
            }
          },
          axisLabel: { show: false },
          max: 1,
          data: [{
            value: itemNum,
            detail: {
              formatter: `{nameStyle|功率因数}\n{valueStyle|${itemNum}}`,
              rich: {
                nameStyle: {
                  color: '#aed6ff',  // 此处写你想要设置的颜色
                  fontSize: 16,  // 该值表示字体大小

                },
                valueStyle: {
                  color: '#aed6ff',  // 此处写你想要设置的颜色
                  fontSize: 16,  // 该值表示字体大小

                }
              }
            }
          }]
        }]
      };
      function findIntervalIndex(value, intervals) {
        for (var i = 0; i < intervals.length - 1; i++) {
          if (value >= intervals[i][0] && value < intervals[i + 1][0]) {
            // intervals[i][1]='#006FB7'
            return i;
          }
        }
        // 如果在最后一个区间之外，返回最后一个区间的索引
        return intervals.length - 1;
      }

      function updateIntervalColor(value, intervals) {
        var intervalIndex = findIntervalIndex(value, intervals);
        // 检查区间之后的偶数位并修改颜色
        for (var i = intervalIndex + 1; i < intervals.length; i += 2) {
          intervals[i][1] = '#006FB7';
        }
      }

      var intervals = [
        [0, 'rgba(0,0,0,0)'], // 空隙的颜色
        [0.1, '#2FC9CB'],
        [0.11, 'rgba(0,0,0,0)'], // 空隙的颜色
        [0.2, '#2FC9CB'],
        [0.21, 'rgba(0,0,0,0)'], // 空隙的颜色
        [0.3, '#2FC9CB'],
        [0.31, 'rgba(0,0,0,0)'], // 空隙的颜色
        [0.4, '#2FC9CB'],
        [0.41, 'rgba(0,0,0,0)'], // 空隙的颜色
        [0.5, '#2FC9CB'],
        [0.51, 'rgba(0,0,0,0)'], // 空隙的颜色
        [0.6, '#2FC9CB'],
        [0.61, 'rgba(0,0,0,0)'], // 空隙的颜色
        [0.7, '#2FC9CB'],
        [0.71, 'rgba(0,0,0,0)'], // 空隙的颜色
        [0.8, '#2FC9CB'],
        [0.81, 'rgba(0,0,0,0)'], // 空隙的颜色
        [0.9, '#2FC9CB'],
        [0.91, 'rgba(0,0,0,0)'],
        [1, '#006FB7']
      ];
      updateIntervalColor(itemNum, intervals);
      option.series[0].axisLine.lineStyle.color = intervals

      chart.setOption(option);
    },
     //底部查询
     onSubmit(){
      const item = this.selectList.find(item => item.value === this.formInline.title);
     this.chartsTitle=item.name
      this.drawEnergy()

    },
    //底部列表
    drawEnergy() {
      let obj={
        stationNumber:this.stationNumber,
        type:this.formInline.title

      }
      emissionsCarbonApi.getStationTwiceTnd(obj).then(res=>{
        console.log(res);
        let data=Object.assign({}, res.data)
        const type = this.formInline.title
        // delete data.station_num
        // delete data
        let all=data[this.formInline.title]
        console.log('~~~~~~~~~~',all);
        const keysArray = Object.keys(data).filter(key => /^\D+\d+$/.test(key)).map(key => parseInt(key.match(/\d+/)[0]));
        const valuesArray = Object.keys(data).filter(key => /^\D+\d+$/.test(key)).map(key => data[key]);
        const combinedArray = keysArray.map((key, index) => ({
            key,
            value: valuesArray[index]?valuesArray[index]:0
        }));
        combinedArray.sort((a, b) => a.key - b.key);
        const sortedKeysArray = combinedArray.map(item => item.key);
        const sortedValuesArray = combinedArray.map(item => item.value);
        sortedKeysArray.unshift('总')
        sortedValuesArray.unshift(all)
        console.log(sortedKeysArray);
        console.log(sortedValuesArray);



        var myEcharts = echarts.init(document.getElementById("contentEcharts"));
        var option = {
          tooltip: {},
  
          grid: {
            left: '3%',
            right: '3%',
            top: '10%',
            bottom: '10%',
           
            containLabel: true
          },
          // legend: {
          //   left: 'right',
          //   top: '0%',
          //   textStyle: {
          //     color: "#AED6FF"
          //   }
          // },
          backgroundColor: '#071838',
          xAxis: {
            data: sortedKeysArray,
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
  
              textStyle: {
                color: '#aed6ff' // 设置 x 轴标签字体颜色
              }
            }
  
          },
          yAxis: {
            name: "单位：%",
            nameTextStyle: {
              color: '#aed6ff',
              fontSize: 14,
            },
            axisLabel: {
              color: '#aed6ff',
              fontSize: 14,
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',
                color: "#0b2159"
              },
  
            },
          },
          series: [
            {
              name: this.chartsTitle,
              type: "bar",
  
              data: sortedValuesArray ,
              barWidth: '10', // 调整柱状图的宽度
              itemStyle: {
                barBorderRadius: [50, 50, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
                color: '#176AD2'
              }
            },
          ],
        };
        myEcharts.setOption({
    dataZoom: [
        {
            type: 'slider',    // 数据缩放类型为滑动条
            start: 0,          // 开始位置（范围为 0 到 100）
            end: 100            // 结束位置（范围为 0 到 100）
        },
        {
            type: 'inside',    // 内置的数据缩放类型，鼠标滚轮缩放
            start: 0,          // 开始位置（范围为 0 到 100）
            end: 100            // 结束位置（范围为 0 到 100）
        }
          ]
      });
  
        myEcharts.setOption(option);
      })









    },
  },
};
</script>
<style src="@/utils/style/table.css" scoped></style>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#wave-monitor {
  width: 100%;
  min-width: 1621px;
  min-height: 903px;
  height: calc(100vh - 170px);
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
}

/* tree */
.tree :deep() .el-tree {
  width: 240px;
  color: #aed6ff;
  background-color: rgba(255, 255, 255, 0);
  padding: 0 0 10px 21px;
  box-sizing: border-box;
}

.tree-title {
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
  margin: 24px 0 21px 21px;
}

::v-deep .el-tree {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .el-tree-node {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .is-current {
  background: #03183f !important;
  color: #ffc74a;
}

::v-deep .is-checked {
  background: #03183f !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183f;
  /* color: #FFC74A; */
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #03183f;
}

::v-deep .el-tree-node {
  background-color: #03183f;
}

::v-deep .el-tree-node:focus>.el-tree-node__content {
  background-color: #03183f;
}

.main {
  min-width: 1376px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  height: calc(100vh - 170px);
}

.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title img {
  width: 20px;
  height: 20px;
}

.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}

/* 查询表单 */
.query-form {
  display: flex;
  align-items: center;
  margin: 18px 0 20px 0;
}

.form :deep() .el-icon-date {
  color: #aed6ff;
}

.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
}

.form :deep() .el-form-item {
  margin: 0 8px 0 0;
}

.form :deep() .el-input {
  width: 132px;
}

.form :deep() .el-checkbox {
  margin-right: 16px;
}

/* input */
.form :deep() .el-input .el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #aed6ff;
}

.select button {
  margin: 0 8px 25px 0;
}

/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}

.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}

::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}

::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}

.table ::v-deep .el-table__body tr:hover>td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table__fixed-right::before {
  height: 0px;
}

::v-deep .el-table__body tr.hover-row>td.el-table__cell {
  background-color: #081f42 !important;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #071734;
}

::v-deep .el-dialog .el-dialog__header {
  background-color: #05153a;
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #c9d4f1;
}

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__footer {
  padding-top: 0;
}

.dialog-content {
  font-size: 16px;
  color: #c9d4f1;
  font-weight: 700;
  margin: 42px 0 64px 32px;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialog-footer button {
  width: 106px;
  height: 36px;
  background: #2142a1;
  border: 0;
  color: #ffffff;
  font-size: 13px;
}

#chart {
  width: 1318px;
  height: 624px;
}

.titleEcharts {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.titleEcharts .titleItem {
  padding: 20px;
  width: 32%;
  height: 400px;
  background: #061A42;
  box-sizing: border-box;
}

.itemTitle {
  display: flex;
}

.itemTitle img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.itemOne {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.oneEcharts {
  width: 400px;
  height: 300px;

}

.threeEcharts {
  width: 100%;
  height: 300px;
}

.itemOne img {
  position: absolute;
}

.itemThree {
  width: 200px;
  margin: 0 auto;
}

.bodyContent {
  background: #04173C;
  margin-top: 16px;
}
.search{
  display: flex;
  justify-content: space-between;
  padding: 16px;
}
.contentTitle{
  text-align: center;
  font-size: 20px;
}
.contentEcharts{
  width: 98%;
  height: 600px;
}
/* 修改select组件的样式 */
.el-select>>>.el-select-dropdown {
  background: #041c4c !important;
  border: 1px solid #09295b;
  color: #aed6ff;
}

::v-deep .el-select-dropdown__item.hover {
  background: #041c4c !important;
  color: #fff;
}

::v-deep .el-select-dropdown__item {
  color: #aed6ff;
}

::v-deep .el-select-dropdown__item.selected {
  color: #fff;
}

.el-select ::placeholder {
  color: #6180a8;
}

.el-select {
  width: 100%;
}
</style>
